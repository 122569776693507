import { defineComponent } from 'vue';
import { callOpenbanking, callOpenbankingTask } from '@/apiCalls/openbankingApi';
import templateMixin from './templateMixin';
import {amountFormatter, OpenBankingTaskType} from '@/utils/openBankingUtils';
import { formatLocaleDate, translate } from '@/i18n';
import { isNil, isNull, isNumber } from 'lodash';
import { setRule } from '@/apiCalls/rulesEngine';

export default defineComponent({
  mixins: [templateMixin],
  data: () => {
    return {
      obStarted: false,
      openBankingLink: '' as String,
      obTaskInterval: null as any,
      obTaskId: '',
      obEnded: false,
      messageOpenBanking: true,
      beginOpenBanking: false,
      showAnalysisDetailed: false,
      showButtonAnalysis: true,
      isAccountSummaryButtonClicked: false
    };
  },
  methods: {
    async generateLink(phone: any, email: any) {
      if (this.journeyId === undefined) {
        await this.createJourneyId('open-banking');
      }

      this.obStarted = true;
      let isEmailValid = false;
      let isPhoneValid = false;
      if (!isNil(phone) && !isNil(phone.value) && phone.value !== '+33') {
        phone.value = phone.value.replaceAll('.', '').replaceAll(' ', '').replaceAll('-', '').replaceAll('_', '');
        isPhoneValid = true;
        setTimeout(() => {
          this.goOpenBanking();
        }, 5000);
      }
      if (!isNull(email) && !isNull(email.value) && email.value !== '') {
        isEmailValid = true;
      }

      this.callOpenBanking(isPhoneValid ? phone.value : null, isEmailValid ? email.value : null);
    },
    callOpenBanking(phone: string | null, mail: string | null) {
      callOpenbanking(phone, this.journeyId, mail)
        .then((res) => {
          this.openBankingLink = res.data.link;
        });
      this.obTaskInterval = callOpenbankingTask(this.journeyId, this.obTaskId, async (successResult: any) => {
        if (this.obTaskInterval != null) {
          clearInterval(this.obTaskInterval);
          this.obTaskInterval = null;
        }

        this.contextData.open_banking_score = successResult?.data?.data?.open_banking_score;
        this.contextData.open_banking_data_summary = successResult?.data?.data?.open_banking_data_summary;
        this.contextData.open_banking_account = successResult?.data?.data?.open_banking_account;

        if (this.$route.path === '/analysis-b2c') {
          const checklist = await setRule('CHECKLIST_B2C', this.journeyId);
          this.checkList = checklist.result;
          this.hasCheckList = true;
        }

        this.obEnded = true;
      }, (errorResult: any) => {
        if (this.obTaskInterval != null) {
          clearInterval(this.obTaskInterval);
          this.obTaskInterval = null;
        }
      });
    },
    goOpenBanking() {
      this.messageOpenBanking = false;
      this.beginOpenBanking = true;
    },
    displayAnalysisDetailed() {
      this.showAnalysisDetailed = true;
      this.showButtonAnalysis = false;
    },
    cleanOpenBanking() {
      this.openBankingLink = '';
      this.contextData = {};
    },
    handleAccountSummary() {
      this.isAccountSummaryButtonClicked = !this.isAccountSummaryButtonClicked;
    },
    calculateOtherCharges(total: number, otherCharges: Array<number>) {
      const sumOfCharges = otherCharges.filter(charge => isNumber(charge)).reduce((acc, curr) => acc + curr, 0);
      return (total - sumOfCharges).toFixed(2);
    }
  },
  computed: {
    obFormattedFields() {
      const obDataSummary = this.openBankingDataSummary;
      const obAccount = this.openBankingAccount;
      const obScore = this.openBankingScore;

      const obTaskIds = this.openBankingTasksId[0]?.taskId;

      if ((JSON.stringify(obDataSummary) == '{}' || !obDataSummary) || (JSON.stringify(obAccount) == '{}' || !obAccount)) {
        return {};
      } else {
      }
      const movements = obDataSummary.movements;
      const alerts = obDataSummary.alerts;
      const aggregations = obDataSummary.aggregations;
      this.displayAnalysisDetailed();

      return {
        'accountName': !isNull(obAccount.name) ? obAccount.name : '-',
        'bankName': !isNull(obAccount.bank_name) ? obAccount.bank_name : '-',
        'iban': !isNull(obAccount.iban) ? obAccount.iban : '-',
        'holderName': !isNull(obAccount.holder_name) ? obAccount.holder_name : '-',
        'currency': obAccount.balance.currency_code,
        'startStudyPeriod': obDataSummary && !isNull(obDataSummary.start_study_period) ? formatLocaleDate(obDataSummary.start_study_period) : '-',
        'endStudyPeriod': obDataSummary && !isNull(obDataSummary.end_study_period) ? formatLocaleDate(obDataSummary.end_study_period) : '-',
        'balance': `${obAccount.balance.value} ${obAccount.balance.currency_code}`,
        'monthEndBalance': !isNull(movements.month_end_balance) ? `${amountFormatter(movements.month_end_balance)} ${obAccount.balance.currency_code}` : '-',
        'monthEndRatio': movements.month_end_ratio ? `${movements.month_end_ratio} %` : '-',
        'score': Math.round(obScore),
        'details': obDataSummary,
        'monthSalaries': obDataSummary.month_outcomes_salaries ? `${amountFormatter(obDataSummary?.month_outcomes_salaries?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'topBankingSupervision': obDataSummary.top_banking_supervision ? translate('YES') : translate('NO'),
        'topMultiRequests': obDataSummary.multi_requests && obDataSummary.multi_requests.iban > 0 ? translate('YES') : translate('NO'),
        'topSecondaryAccount': obDataSummary.top_secondary_account ? translate('NO') : translate('YES'),
        'remainsToLive': aggregations.remains_to_live ? `${amountFormatter(aggregations.remains_to_live)} ${obAccount.balance.currency_code}` : '-',
        'monthRents': obDataSummary.month_outcomes_rents ? `${amountFormatter(obDataSummary.month_outcomes_rents.value)} ${obAccount.balance.currency_code}` : '-',
        'globalTransactionsCount': obDataSummary.global_transactions_count,
        'topDeferredDebit': obDataSummary.top_deferred_debit ? translate('YES') : translate('NO'),
        'monthIncomesAmountMax': aggregations.month_incomes ? `${amountFormatter(aggregations.month_incomes?.amount?.max)} ${obAccount.balance.currency_code}` : '-',
        'monthIncomesAmountMin': aggregations.month_incomes ? `${amountFormatter(aggregations.month_incomes?.amount?.min)} ${obAccount.balance.currency_code}` : '-',
        'monthTransactionsCount': obDataSummary.month_transactions_count,

        // income
        'monthOutcomesRentsMean': aggregations.month_outcomes_rents ? `${amountFormatter(aggregations.month_outcomes_rents?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesCreditsDateMean': aggregations.month_outcomes_credits ? `${Math.round(aggregations.month_outcomes_credits.date.mean)} ${translate('OF_MONTH')}` : '-',
        'monthOutcomesPensionsDateMean': aggregations.month_outcomes_pensions ? `${Math.round(aggregations.month_outcomes_pensions?.amount?.mean)} ${translate('OF_MONTH')}` : '-',

        // outcome
        'recommendedDebitDate': movements.recommended_debit ? `${movements.recommended_debit.date} ${translate('OF_MONTH')}` : '-',
        'recommendedDebitAmount': movements.recommended_debit ? `${amountFormatter(movements.recommended_debit.amount)} ${obAccount.balance.currency_code}` : '-',
        'monthNegativeBalanceMin': movements.month_negative_balance ? `${movements.month_negative_balance.date?.min} ${translate('OF_MONTH')}` : '-',
        'monthNegativeBalanceMax': movements.month_negative_balance ? `${movements.month_negative_balance.date?.max} ${translate('OF_MONTH')}` : '-',
        'monthNegativeBalanceMean': movements.month_negative_balance ? `${movements.month_negative_balance.date?.mean} ${translate('OF_MONTH')}` : '-',
        'maxBalance': movements.max_balance ? `${formatLocaleDate(movements.max_balance.date)}` : '-',
        'monthOutcomesCreditsMin': aggregations.month_outcomes_credits ? `${amountFormatter(aggregations.month_outcomes_credits?.amount?.min)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesCreditsMax': aggregations.month_outcomes_credits ? `${amountFormatter(aggregations.month_outcomes_credits?.amount?.max)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesCreditsMean': aggregations.month_outcomes_credits ? `${amountFormatter(aggregations.month_outcomes_credits?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesCreditsCountMin': aggregations.month_outcomes_credits ? `${amountFormatter(aggregations.month_outcomes_credits.count.min)}` : '-',
        'monthOutcomesCreditsCountMax': aggregations.month_outcomes_credits ? `${amountFormatter(aggregations.month_outcomes_credits.count.max)}` : '-',
        'monthOutcomesCreditsCountMean': aggregations.month_outcomes_credits ? `${aggregations.month_outcomes_credits.count.mean}` : '-',
        'debtRatio': aggregations.debt_ratio ? `${aggregations.debt_ratio} %` : '-',
        'monthOutcomesRentsMin': aggregations.month_outcomes_rents ? `${amountFormatter(aggregations.month_outcomes_rents?.amount?.min)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesRentsMax': aggregations.month_outcomes_rents ? `${amountFormatter(aggregations.month_outcomes_rents?.amount?.max)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesUtilitiesMin': aggregations.month_outcomes_utilities ? `${amountFormatter(aggregations.month_outcomes_utilities?.amount?.min)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesUtilitiesMax': aggregations.month_outcomes_utilities ? `${amountFormatter(aggregations.month_outcomes_utilities?.amount?.max)} ${obAccount.balance.currency_code}` : '-',
        'alerts': alerts,
        'bankCardPresence': aggregations.bank_card ? (aggregations.bank_card.presence ? translate('YES') : translate('NO')) : translate('NO'),
        'bankCardQuantity': aggregations.bank_card ? aggregations.bank_card.numbers.length : '-',
        'bankCardNumbers': aggregations.bank_card ? aggregations.bank_card.numbers : '-',
        'monthOutcomesRejects': obDataSummary.month_outcomes_rejects ? `${amountFormatter(obDataSummary.month_outcomes_rejects)} ${obAccount.balance.currency_code} ${translate('RECOVERY.ON_AVERAGE_PER_MONTH')}` : '-',
        'monthOutcomesInterventionFees': obDataSummary.month_outcomes_intervention_fees ? `${amountFormatter(obDataSummary.month_outcomes_intervention_fees.value)} ${obAccount.balance.currency_code} ${translate('RECOVERY.ON_AVERAGE_PER_MONTH')}` : '-',
        'monthOutcomesGamblings': obDataSummary.month_outcomes_gamblings ? `${amountFormatter(obDataSummary.month_outcomes_gamblings.value)} ${obAccount.balance.currency_code} ${translate('RECOVERY.ON_AVERAGE_PER_MONTH')}` : '-',
        'monthOutcomesSeizureFees': aggregations.month_outcomes_seizure_fees ? `${amountFormatter(aggregations.month_outcomes_seizure_fees?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesCashCollectionsAmountMean': aggregations.month_outcomes_cash_collections ? `${amountFormatter(aggregations.month_outcomes_cash_collections?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'topBankRiskLevel': obDataSummary.top_bank_risk_level,
        'averageMonthlyEntries': aggregations.month_incomes ? `${amountFormatter(aggregations.month_incomes?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'netIncome': aggregations.net_income ? `${amountFormatter(aggregations.net_income.toFixed(2))} ${obAccount.balance.currency_code}` : '-',
        'turnover': aggregations.turnover ? `${amountFormatter(aggregations.turnover)} ${obAccount.balance.currency_code}` : '-',
        'balancePerMonth': movements.balance_per_month ? movements.balance_per_month : [],
        'balancePerDay': movements.balance_per_day ? movements.balance_per_day : [],
        'topProAccount': obDataSummary.top_pro_account,
        'obTaskId': obTaskIds,
        'bankDirectDebitPresence': aggregations.bank_direct_debit ? aggregations.bank_direct_debit.presence ? translate('YES') : translate('NO') : '-',

        'multiRequests': obDataSummary.multi_requests ? obDataSummary.multi_requests : [],

        //incomes
        'monthIncomesTransfersAmountMean': aggregations.month_incomes_autotransfers ? `${amountFormatter(aggregations.month_incomes_autotransfers?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthIncomesTransfersAmountSum': aggregations.month_incomes_autotransfers ? `${amountFormatter(aggregations.month_incomes_autotransfers?.amount?.sum)} ${obAccount.balance.currency_code}` : '-',
        'monthIncomesTransfersAmountYearlyEstimation': aggregations.month_incomes_autotransfers && aggregations.month_incomes_autotransfers?.amount?.yearly_estimation ? `${amountFormatter(aggregations.month_incomes_autotransfers?.amount?.yearly_estimation)} ${obAccount.balance.currency_code}` : '-',

        'monthIncomesSalariesAmountMean': aggregations.month_incomes_salaries ? `${amountFormatter(aggregations.month_incomes_salaries?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthIncomesSalariesAmountSum': aggregations.month_incomes_salaries ? `${amountFormatter(aggregations.month_incomes_salaries?.amount?.sum)} ${obAccount.balance.currency_code}` : '-',
        'monthIncomesSalariesAmountYearlyEstimation': aggregations.month_incomes_salaries && aggregations.month_incomes_salaries?.amount?.yearly_estimation ? `${amountFormatter(aggregations.month_incomes_salaries?.amount?.yearly_estimation)} ${obAccount.balance.currency_code}` : '-',

        'monthIncomesAllowancesAmountMean': aggregations.month_incomes_allowances ? `${amountFormatter(aggregations.month_incomes_allowances?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthIncomesAllowancesAmountSum': aggregations.month_incomes_allowances ? `${amountFormatter(aggregations.month_incomes_allowances?.amount?.sum)} ${obAccount.balance.currency_code}` : '-',
        'monthIncomesAllowancesAmountYearlyEstimation': aggregations.month_incomes_allowances && aggregations.month_incomes_allowances?.amount?.yearly_estimation ? `${amountFormatter(aggregations.month_incomes_allowances?.amount?.yearly_estimation)} ${obAccount.balance.currency_code}` : '-',

        'monthIncomesPensionsAmountMean': aggregations.month_incomes_pensions ? `${amountFormatter(aggregations.month_incomes_pensions?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthIncomesPensionsAmountSum': aggregations.month_incomes_pensions ? `${amountFormatter(aggregations.month_incomes_pensions?.amount?.sum)} ${obAccount.balance.currency_code}` : '-',
        'monthIncomesPensionsAmountYearlyEstimation': aggregations.month_incomes_pensions && aggregations.month_incomes_pensions?.amount?.yearly_estimation ? `${amountFormatter(aggregations.month_incomes_pensions?.amount?.yearly_estimation)} ${obAccount.balance.currency_code}` : '-',

        'monthIncomesAmountMean': aggregations.month_incomes ? `${amountFormatter(aggregations.month_incomes?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthIncomesAmountSum': aggregations.month_incomes ? `${amountFormatter(aggregations.month_incomes?.amount?.sum)} ${obAccount.balance.currency_code}` : '-',
        'monthIncomesAmountYearlyEstimation': aggregations.month_incomes && aggregations.month_incomes?.amount?.yearly_estimation ? `${amountFormatter(aggregations.month_incomes?.amount?.yearly_estimation)} ${obAccount.balance.currency_code}` : '-',

        'monthIncomesSavingsAmountMean': aggregations.month_incomes_savings ? `${amountFormatter(aggregations.month_incomes_savings?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthIncomesSavingsAmountSum': aggregations.month_incomes_savings ? `${amountFormatter(aggregations.month_incomes_savings?.amount?.sum)} ${obAccount.balance.currency_code}` : '-',
        'monthIncomeSavingsAmountYearlyEstimation': aggregations.month_incomes_savings && aggregations.month_incomes_savings?.amount?.yearly_estimation ? `${amountFormatter(aggregations.month_incomes_savings?.amount?.yearly_estimation)} ${obAccount.balance.currency_code}` : '-',

        //outcomes
        'monthOutcomesRentsAmountMean': aggregations.month_outcomes_rents ? `${amountFormatter(aggregations.month_outcomes_rents?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesRentsAmountSum': aggregations.month_outcomes_rents ? `${amountFormatter(aggregations.month_outcomes_rents?.amount?.sum)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesRentsAmountYearlyEstimation': aggregations.month_outcomes_rents && aggregations.month_outcomes_rents?.amount?.yearly_estimation ? `${amountFormatter(aggregations.month_outcomes_rents?.amount?.yearly_estimation)} ${obAccount.balance.currency_code}` : '-',

        'monthOutcomesSalariesAmountMean': aggregations.month_outcomes_salaries ? `${amountFormatter(aggregations.month_outcomes_salaries?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesSalariesAmountSum': aggregations.month_outcomes_salaries ? `${amountFormatter(aggregations.month_outcomes_salaries?.amount?.sum)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesSalariesAmountYearlyEstimation': aggregations.month_outcomes_salaries && aggregations.month_outcomes_salaries?.amount?.yearly_estimation ? `${amountFormatter(aggregations.month_outcomes_salaries?.amount?.yearly_estimation)} ${obAccount.balance.currency_code}` : '-',

        'monthOutcomesCreditsAmountMean': aggregations.month_outcomes_credits ? `${amountFormatter(aggregations.month_outcomes_credits?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesCreditsAmountSum': aggregations.month_outcomes_credits ? `${amountFormatter(aggregations.month_outcomes_credits?.amount?.sum)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesCreditsAmountYearlyEstimation': aggregations.month_outcomes_credits && aggregations.month_outcomes_credits?.amount?.yearly_estimation ? `${amountFormatter(aggregations.month_outcomes_credits?.amount?.yearly_estimation)} ${obAccount.balance.currency_code}` : '-',

        'monthOutComesCreditsAmountMin': aggregations.month_outcomes_credits ? `${amountFormatter(aggregations.month_outcomes_credits?.amount?.min)} ${obAccount.balance.currency_code}` : '-',
        'monthOutComesCreditsAmountMax': aggregations.month_outcomes_credits ? `${amountFormatter(aggregations.month_outcomes_credits?.amount?.max)} ${obAccount.balance.currency_code}` : '-',

        'monthOutcomesTaxesAmountMean': aggregations.month_outcomes_taxes ? `${amountFormatter(aggregations.month_outcomes_taxes?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesTaxesAmountSum': aggregations.month_outcomes_taxes ? `${amountFormatter(aggregations.month_outcomes_taxes?.amount?.sum)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesTaxesAmountYearlyEstimation': aggregations.month_outcomes_taxes && aggregations.month_outcomes_taxes?.amount?.yearly_estimation ? `${amountFormatter(aggregations.month_outcomes_taxes?.amount?.yearly_estimation)} ${obAccount.balance.currency_code}` : '-',

        'monthOutcomesAutoTransfersAmountMean': aggregations.month_outcomes_autotransfers ? `${amountFormatter(aggregations.month_outcomes_autotransfers?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesAutoTransfersAmountSum': aggregations.month_outcomes_autotransfers ? `${amountFormatter(aggregations.month_outcomes_autotransfers?.amount?.sum)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesAutoTransfersAmountYearlyEstimation': aggregations.month_outcomes_autotransfers && aggregations.month_outcomes_autotransfers?.amount?.yearly_estimation ? `${amountFormatter(aggregations.month_outcomes_autotransfers?.amount?.yearly_estimation)} ${obAccount.balance.currency_code}` : '-',

        'monthOutcomesAmountMean': aggregations.month_outcomes ? `${amountFormatter(aggregations.month_outcomes?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesAmountSum': aggregations.month_outcomes ? `${amountFormatter(aggregations.month_outcomes?.amount?.sum)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesAmountYearlyEstimation': aggregations.month_outcomes && aggregations.month_outcomes?.amount?.yearly_estimation ? `${amountFormatter(aggregations.month_outcomes?.amount?.yearly_estimation)} ${obAccount.balance.currency_code}` : '-',

        'monthOutcomesCreditsKeywords': aggregations.month_outcomes_credits && aggregations.month_outcomes_credits.keywords.length > 0 ? aggregations.month_outcomes_credits.keywords : '-',

        'monthOutcomesUtilitiesAmountMean': aggregations.month_outcomes_utilities ? `${amountFormatter(aggregations.month_outcomes_utilities?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesUtilitiesAmountSum': aggregations.month_outcomes_utilities ? `${amountFormatter(aggregations.month_outcomes_utilities?.amount?.sum)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesUtilitiesAmountYearlyEstimation': aggregations.month_outcomes_utilities && aggregations.month_outcomes_utilities?.amount?.yearly_estimation ? `${amountFormatter(aggregations.month_outcomes_utilities?.amount?.yearly_estimation)} ${obAccount.balance.currency_code}` : '-',

        'monthOutcomesSavingsAmountMean': aggregations.month_outcomes_savings ? `${amountFormatter(aggregations.month_outcomes_savings?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesSavingsAmountSum': aggregations.month_outcomes_savings ? `${amountFormatter(aggregations.month_outcomes_savings?.amount?.sum)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesSavingsAmountYearlyEstimation': aggregations.month_outcomes_savings && aggregations.month_outcomes_savings?.amount?.yearly_estimation ? `${amountFormatter(aggregations.month_outcomes_savings?.amount?.yearly_estimation)} ${obAccount.balance.currency_code}` : '-',

        'monthOutcomesTransfersAmountMean': aggregations.month_outcomes_autotransfers ? `${amountFormatter(aggregations.month_outcomes_autotransfers?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesTransfersAmountSum': aggregations.month_outcomes_autotransfers ? `${amountFormatter(aggregations.month_outcomes_autotransfers?.amount?.sum)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesTransfersAmountYearlyEstimation': aggregations.month_outcomes_autotransfers && aggregations.month_outcomes_autotransfers?.amount?.yearly_estimation ? `${amountFormatter(aggregations.month_outcomes_autotransfers?.amount?.yearly_estimation)} ${obAccount.balance.currency_code}` : '-',

        'monthOutcomesSocialFeesMean': aggregations.month_outcomes_social_fees ? `${amountFormatter(aggregations.month_outcomes_social_fees?.amount?.mean)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesSocialFeesSum': aggregations.month_outcomes_social_fees ? `${amountFormatter(aggregations.month_outcomes_social_fees?.amount?.sum)} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesSocialFeesYearlyEstimation': aggregations.month_outcomes_social_fees && aggregations.month_outcomes_social_fees?.amount?.yearly_estimation ? `${amountFormatter(aggregations.month_outcomes_social_fees?.amount?.yearly_estimation)} ${obAccount.balance.currency_code}` : '-',

        'monthOutcomesOtherExpensesInclVatAmountMean': aggregations.month_outcomes && aggregations.month_outcomes_rents ? `${amountFormatter(this.calculateOtherCharges(aggregations.month_outcomes?.amount?.mean, [aggregations.month_outcomes_rents?.amount?.mean, aggregations.month_outcomes_salaries?.amount?.mean, aggregations.month_outcomes_credits?.amount?.mean, aggregations.month_outcomes_taxes?.amount?.mean, aggregations.month_outcomes_autotransfers?.amount?.mean, aggregations.month_outcomes_social_fees?.amount?.mean]))} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesOtherExpensesInclVatAmountSum': aggregations.month_outcomes && aggregations.month_outcomes_rents ? `${amountFormatter(this.calculateOtherCharges(aggregations.month_outcomes?.amount?.sum, [aggregations.month_outcomes_rents?.amount?.sum, aggregations.month_outcomes_salaries?.amount?.sum, aggregations.month_outcomes_credits?.amount?.sum, aggregations.month_outcomes_taxes?.amount?.sum, aggregations.month_outcomes_autotransfers?.amount?.sum, aggregations.month_outcomes_social_fees?.amount?.sum]))} ${obAccount.balance.currency_code}` : '-',
        'monthOutcomesOtherExpensesInclVatAmountYearlyEstimation': aggregations.month_outcomes && aggregations.month_outcomes_rents ? `${amountFormatter(this.calculateOtherCharges(aggregations.month_outcomes?.amount?.yearly_estimation, [aggregations.month_outcomes_rents?.amount?.yearly_estimation, aggregations.month_outcomes_salaries?.amount?.yearly_estimation, aggregations.month_outcomes_credits?.amount?.yearly_estimation, aggregations.month_outcomes_taxes?.amount?.yearly_estimation, aggregations.month_outcomes_autotransfers?.amount?.yearly_estimation, aggregations.month_outcomes_social_fees?.amount?.yearly_estimation]))} ${obAccount.balance.currency_code}` : '-'
      };
    },
    checkDataAvailability() {
      const obDataSummary = this.openBankingDataSummary;
      const obAccount = this.openBankingAccount;

      if (obDataSummary && obAccount) {
        const { start_study_period, end_study_period } = obDataSummary;
        const { iban } = obAccount;
        if (isNil(start_study_period) && isNil(end_study_period) && isNil(iban)) {
          return false;
        }
      }
      return true;
    },
    openBankingResumeBlock() {
      if (this.$route.path === '/analysis-b2c') {
        return this.permissionsSorted.includes('KIT_B2C_OB_RESUME_GLOBAL');
      } else if (this.$route.path === '/analysis-b2b') {
        return this.permissionsSorted.includes('KIT_B2B_OB_RESUME_GLOBAL');
      } else if (this.$route.path === '/synthesis') {
        return this.permissionsSorted?.some((elt: any) => elt.includes('KIT_B2C_OB_RESUME_GLOBAL') || elt.includes('KIT_B2B_OB_RESUME_GLOBAL') || elt.includes('KIT_OB_OB_RESUME_GLOBAL'));
      }
    },
    openBankingDetailBlock() {
      return this.permissionsOpenBankingDetails[0].length > 0;
    },
    openBankingAlertBlock() {
      if (this.$route.path === '/analysis-b2c') {
        return this.permissionsSorted.includes('KIT_B2C_OB_ALERTS_GLOBAL');
      } else if (this.$route.path === '/analysis-b2b') {
        return this.permissionsSorted.includes('KIT_B2B_OB_ALERTS_GLOBAL');
      } else if (this.$route.path === '/synthesis') {
        return this.permissionsSorted?.some((elt: any) => elt.includes('KIT_B2B_OB_ALERTS_GLOBAL') || elt.includes('KIT_B2C_OB_ALERTS_GLOBAL') || elt.includes('KIT_OB_OB_ALERTS_GLOBAL'));
      }
    },
    canShowBoxOutputOpenBanking() {
      return (Object.keys(this.obFormattedFields).length > 0) && !this.isDuplicateFolder();
    },
    canShowBoxOutputOpenBankingDetail() {
      return (Object.keys(this.obFormattedFields).length > 0) && !this.isDuplicateFolder();
    },
    canShowBoxOutputOpenBankingAlert() {
      return (Object.keys(this.obFormattedFields).length > 0) && !this.isDuplicateFolder();
    }
  }
});